export default {
	data() {
		return {
			url: '',
			addUrl: '',
			editUrl: '',
			delUrl: '',
			searchParams: {
				pageNum: 1,
				pageSize: 50
			},
			loading:true,
			async: true,
			page: {
				currentPage: 1,
				pageSizes: [50, 100, 150, 200],
				pageSize: 50,
				total: 0
			},
			tableDataList: [],
			search: '', //表格内的模糊搜索
		}
	},
	filters:{
		numToString(value) {
			return value.toString()
		},
		moneyFortmat(val) {
			if(val<0) {
				val = 0-val
			}
			if(val) {
				return "¥" + Number(val).toFixed(2);
			}
		},
		weightFormat(val) {
			return Number(val).toFixed(2)
		}
	},
	created() {
		this.getTableDataList();
	},
	methods: {
		isEmpty(val) {
			if(val === '' || val === null || val === undefined) {
				return false;
			}else {
				return  true;
			}
		},
		showLoading() {
			if(this.loading) {
				this.main.showLoading();
			}
		},
		hideLoading() {
			if(this.loading) {
				this.main.hideLoading()
			}
		},
		//post方式获取分页table数据
		getTableDataList() {
			let that = this;
			if (that.url == '') return;
			this.showLoading();
			that.axios.post(that.url, that.searchParams, null).then(res => {
				if (res.data.code === 0) {
					console.log(res.data.data)
				} else {
					that.$message.warning(res.data.msg);
				}
				that.tableDataList = res.data.data.records;
				that.page.total = res.data.data.total;
				this.hideLoading();
			}).catch(err => {
				that.$message.error(err.message);
				this.hideLoading();
			})
			// that.$ajax(that.url, 'POST', that.searchParams, that.async, function(res) {
			// 	that.tableDataList = res.data.records;
			// 	that.page.total = res.data.total;
			// })
		},
		//分页
		handleCurrentChange(val) {
			this.searchParams.pageNum = val;
			this.page.currentPage = val;
			this.getTableDataList();
		},
		handleSizeChange(val) {
			this.searchParams.pageSize = val;
			this.page.currentPage = this.searchParams.pageNum;
			this.getTableDataList();
		},
		//新增方法
		addHandle(data) {
			let that = this;
			if (that.addUrl === '') return;
			that.axios.post(that.addUrl, data, {}).then(res => {
				if (res.data.code === 0) {
					that.$message.success('新增成功!')
					that.getTableDataList()
				} else {
					that.$message.warning(res.data.msg)
				}
			}).catch(err => {
				that.$message.error(err.message);
			})
		},
		//修改方法
		editHandle(data) {
			let that = this;
			if (that.editUrl === '') return;
			that.axios.put(that.editUrl, data, null).then(res => {
				if (res.data.code === 0) {
					that.$message.success('修改成功。')
					that.getTableDataList()
				} else {
					that.$message.warning(res.data.msg)
				}
			}).catch(err => {
				that.$message.error(err.message);
			})
		},
		//删除方法,删除单个
		delHandle(row) {
			let that = this
			if (that.delUrl === '') return;
			that.$confirm('是否确认删除?' + row.id, '提示', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.axios.delete(that.delUrl+row.id,null,null).then(res=>{
					if(res.data.code === 0) {
						that.$message.success('成功删除所选项...')
					} else {
						that.$message.warning(res.data.msg)
					}
				}).catch(err=>{
					that.$message.error(err.message);
				})
				that.getTableDataList()
			}).catch(err => {
				that.$message.error(err.message);
			})
		},

	},
}
